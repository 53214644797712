<template>
  <div v-if="!isLoading">
    <div class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Transactions</h3>
          <div class="flex gap-8">
            <input v-model="keyword" aria-label="Search" class="form-control" placeholder="Search..." type="text" />
            <select v-model="status" aria-label="Status" class="form-control w-200">
              <option v-for="(s, index) in Object.keys(statusList)" :key="index" :value="s">{{ statusList[s] }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div class="row">
          <div class="col-md-3">
            <ul>
              <li v-for="(altCoin, index) in onlineAltCoins" :key="index">
                <router-link :to="{ name: 'transactions', params: { name: altCoin.LongName } }" tag="a">
                  {{ altCoin.Name + ' ' + altCoin.LongName }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <div v-if="filteredTransactions && filteredTransactions.length" class="footer">
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-condensed mb-0">
                <thead>
                  <tr>
                    <th>Merchant</th>
                    <th>Transaction ID</th>
                    <th class="text-right">Original Amount</th>
                    <th class="text-right">Amount</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="paginatedTransactions.length > 0">
                    <tr v-for="(transaction, index) in paginatedTransactions" :key="index">
                      <td class="no-wrap">
                        <router-link :to="{ name: 'user-detail', params: { id: transaction.MerchantID } }" tag="a">
                          {{ transaction.OwnerNickName + ' - ' + transaction.ShopTitle }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{ name: 'transaction-detail', params: { id: transaction.Prefix + transaction.TransactionID.toString().padStart(9, '0') } }"
                          tag="a">
                          {{ transaction.Prefix + transaction.TransactionID.toString().padStart(9, '0') }}
                        </router-link>
                      </td>
                      <td class="text-right nowrap">
                        {{ toFixed(transaction.OriginalAmount) + ' ' + transaction.InputCurrency }}
                      </td>
                      <td class="text-right nowrap">{{ transaction.Amount.toFixed(8) + ' ' + transaction.LongName }}
                      </td>
                      <td class="nowrap">{{
    transaction.ExternalTransactionProvider ? transaction.ExternalTransactionProvider :
      transaction.TransactionType
  }}
                      </td>
                      <td>
                        <span v-html="fs(transaction.Status)"></span>
                      </td>
                      <td class="nowrap">{{ fd(transaction.CreatedOn) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="7">No record found!</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="filteredTransactions && filteredTransactions.length" class="footer">
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "../store/keys";
import { formatDate, formatStatus, toFixed } from "@/lib/utils";
import Pagination from "@/components/Pagination";

export default {
  name: "Transactions",
  components: {
    Pagination,
  },
  data() {
    return {
      transactions: [],
      currentPage: 1,
      limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      keyword: "",
      status: "",
      statusList: {
        "": "All",
        waiting: "Waiting",
        expired: "Expired",
        paid: "Paid",
        underpaid: "Underpaid",
        "rt-t": "Reflextrader Transaction",
        "rt-p": "Reflextrader Payout",
        "vtoken-active": "vToken Active",
        "vtoken-claimed": "vToken Claimed",
        "ltr-open": "LTR Open",
        "ltr-closed": "LTR Closed",
        "ltr-expired": "LTR Expired",
        "payout completed": "Payout Completed",
        "payout pending": "Payout Pending",
        "payout cancelled": "Payout Cancelled",
      },
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "onlineAltCoins",
      "jwtToken",
    ]),
    filteredTransactions() {
      let transactions = this.transactions.filter(transaction => {
        const type = transaction.ExternalTransactionProvider ? transaction.ExternalTransactionProvider : transaction.TransactionType;

        if (this.keyword && this.status && this.status !== "all") {
          let keyword = this.keyword.trim().toLowerCase();
          if ((keyword.startsWith("t") || keyword.startsWith("p")) && keyword.length === 10) {
            keyword = Number.parseInt(keyword.replace(/[a-z]/, "")).toString();
          }
          const status = (this.status.startsWith("rt-")) ? "reflextrader" : this.status;
          const prefix = status === "reflextrader" ? this.status.split("-")[1] : "";
          return (this._.includes(transaction.ShopTitle.toLowerCase(), keyword) ||
            this._.includes(transaction.TransactionID.toString(), keyword) ||
            this._.includes(transaction.MerchantID.toString(), keyword) ||
            this._.includes(transaction.Security.toLowerCase(), keyword) ||
            this._.includes(transaction.Comments && transaction.Comments.toLowerCase(), keyword) ||
            this._.includes(transaction.CoinName.toLowerCase(), keyword) ||
            this._.includes(transaction.LongName.toLowerCase(), keyword) ||
            this._.includes(transaction.CustomerReferenceNr && transaction.CustomerReferenceNr.toLowerCase(), keyword) ||
            this._.includes(this.toFixed(transaction.Amount).toString(), keyword) ||
            this._.includes(transaction.CoinAddress.toLowerCase(), keyword) ||
            this._.includes(this.toFixed(transaction.OriginalAmount).toString(), keyword) ||
            this._.includes(transaction.CreatedOn.toString(), keyword) ||
            this._.includes(type.toLowerCase(), keyword)) &&
            ((status !== "reflextrader" && transaction.Status === this.status) || (transaction.Status === status && transaction.Prefix.toLowerCase() === prefix));
        } else if (this.keyword) {
          let keyword = this.keyword.trim().toLowerCase();
          if ((keyword.startsWith("t") || keyword.startsWith("p")) && keyword.length === 10) {
            keyword = Number.parseInt(keyword.replace(/[a-z]/, "")).toString();
          }
          return this._.includes(transaction.ShopTitle.toLowerCase(), keyword) ||
            this._.includes(transaction.TransactionID.toString(), keyword) ||
            this._.includes(transaction.MerchantID.toString(), keyword) ||
            this._.includes(transaction.Security.toLowerCase(), keyword) ||
            this._.includes(transaction.Comments && transaction.Comments.toLowerCase(), keyword) ||
            this._.includes(transaction.CoinName.toLowerCase(), keyword) ||
            this._.includes(transaction.LongName.toLowerCase(), keyword) ||
            this._.includes(transaction.CustomerReferenceNr && transaction.CustomerReferenceNr.toLowerCase(), keyword) ||
            this._.includes(this.toFixed(transaction.Amount).toString(), keyword) ||
            this._.includes(transaction.CoinAddress.toLowerCase(), keyword) ||
            this._.includes(this.toFixed(transaction.OriginalAmount).toString(), keyword) ||
            this._.includes(transaction.CreatedOn.toString(), keyword) ||
            this._.includes(type.toLowerCase(), keyword);
        } else if (this.status && this.status !== "all") {
          if (this.status === "rt-t" || this.status === "rt-p") {
            const prefix = this.status.split("-")[1];
            return transaction.Status === "reflextrader" && transaction.Prefix.toLowerCase() === prefix;
          } else {
            return transaction.Status === this.status;
          }
        }
        return true;
      });
      transactions = this._.orderBy(transactions, ["CreatedOn"], ["desc"]);
      return transactions;
    },
    paginatedTransactions() {
      if (this.filteredTransactions.length > 0) {
        return this.filteredTransactions.slice(this.offset * this.limit, this.currentPage * this.limit);
      }
      return this.filteredTransactions;
    },
    offset() {
      return this.currentPage > 0 ? this.currentPage - 1 : 0;
    },
    meta() {
      return {
        total: this.filteredTransactions.length,
        offset: this.offset * this.limit,
        limit: this.limit,
        page: this.currentPage,
      };
    },
  },
  watch: {
    filteredTransactions(value) {
      if (this.keyword !== "" || this.status !== "all") {
        this.updatePage(1);
      }
    },
    $route(to, from) {
      if (to.params.name !== from.params.name) {
        const name = to.params.name;
        const coin = this._.find(this.onlineAltCoins, { LongName: name });
        if (coin) {
          this.listTransactions(coin.ID);
        }
      }
    },
  },
  methods: {
    updatePage(page) {
      this.currentPage = page;
    },
    fs(status) {
      return formatStatus(status);
    },
    fd(val) {
      return formatDate(val);
    },
    toFixed(val) {
      return toFixed(val);
    },
    listTransactions(id) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=TransactionsPerCurrency`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: id,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.transactions = response.data.data;
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        console.log(error);
      });
    },
  },
  mounted() {
    let id = this.onlineAltCoins[0].ID;
    if (this.$route.params.name) {
      const name = this.$route.params.name;
      const coin = this._.find(this.onlineAltCoins, { LongName: name });
      if (coin) {
        id = coin.ID;
      }
    }
    this.listTransactions(id);
  },
};
</script>

<style scoped lang="scss">
ul {
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 2px;

    a {
      display: block;
      padding: 0.5rem 1rem;
      background-color: #f9f9f9;
      color: #808080;

      &:hover,
      &:focus,
      &.active,
      &:active {
        background-color: #e9e9e9;
        color: #606060;
      }
    }
  }
}
</style>
